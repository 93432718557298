<template>
  <div class="moduleWrap">
      <dl>
        <dd class title="联系">
          <i class="iconfont iconkefu"></i>
          <p class="p">联系</p>
          <div class="box">
            <div class="server">
              <p>
                <a
                  href="http://wpa.qq.com/msgrd?v=3&amp;uin=37579743&amp;site=qq&amp;menu=yes"
                  target="_blank"
                >
                  <em class="iconfont iconqq"></em>37579743
                </a>
              </p>
              <p>
                <em class="iconfont icondianhua"></em>商务联系：
                <br />400-728-8939
              </p>
              <p>
                周一至周日：
                <br />9:00-21:00
              </p>
            </div>
          </div>
        </dd>
        <dd class title="扫一扫">
          <i class="iconfont iconico"></i>
          <p class="p">扫一扫</p>
          <div class="box codeserverbox">
            <div class="codeserver">
              <p style="margin-bottom:5px">
                商务联系
                <!-- p -->
                <img src="../assets/image/b2c_contact.png" />
              </p>
            </div>
            <div class="codeserver">
              <p style="margin-bottom:5px">
                产品体验
                <!-- p -->
                <img src="../assets/image/b2c_sp.jpg" />
              </p>
            </div>
            <!-- <div class="codeserver">
              <p style="margin-bottom:5px">
                官方服务号
                <img src="../assets/image/b2c_sp.jpg" />
              </p>
            </div> -->
          </div>
        </dd>
        <dd class="goto-top" title="返回顶部">
          <i class="iconfont iconarrow-fine-up" @click="goTop"></i>
          <p class="p">回顶部</p>
        </dd>
      </dl>
    </div>
</template>

<script>
export default {
  name: "footer",
  data(){
    return{
      
    }
  },
  methods:{
   goTop(){
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    }
  },
  props: {},
  created(){
    
  }
};
</script>

<style scoped>
.moduleWrap{position:fixed;bottom:150px;right:12px;width:auto;min-width:0;z-index:101}
.moduleWrap dl{padding:5px 0px}
.moduleWrap dd .hover-code{position:absolute;  right:100%;bottom:0px;transform: scale(.6);transition: transform .3s;    visibility: hidden;}
.moduleWrap dd .icon-img{display:inline-block;width:86px;height:73px;}
.moduleWrap dd .icon-img{transition: transform .3s;}
.moduleWrap dd .icon-img:hover{ transform: rotateY(180deg);} 
.moduleWrap dd:hover .hover-code{    visibility: visible;transform: scale(1);}
.moduleWrap dd a{display:block;position:relative;}

.moduleWrap dd{display:block;width:55px;position:relative;text-align:center;cursor:pointer;padding:3px 0px;margin:7px 0px;height:56px; background: #e62d31;box-shadow: 0 6px 12px 0 rgba(0,0,0,.15);}
.moduleWrap dd .icondiv{float:left;width:45px;position:absolute;overflow:hidden}
.moduleWrap dd .icondiv2{left:45px;opacity:0}
.moduleWrap dd:hover i{font-size:26px}
.moduleWrap dd i{font-size:24px;color:#fff;transition: 0.3s;display:  block;height: 30px;line-height: 32px;}
.moduleWrap dd .p{color:#fff;line-height:18px}
.moduleWrap dd .panelCont{position:absolute;transition: all .3s;visibility: hidden;opacity:0;bottom:0px;right: 45px;color:#333;text-align:left;width:320px;z-index:4;padding-right: 10px;}
.moduleWrap dd .panelCont ul{background:#fff;padding:10px 20px;box-shadow:0 3px 12px rgba(0,0,0,.15);}
.moduleWrap dd .panelCont li{overflow:hidden;padding:8px 0}
.moduleWrap dd .panelCont li .iconfont1{margin-top:4px; float:left;display:inline-block;color:#333; vertical-align:middle;overflow:hidden;font-size:26px;width:40px;text-align:center;color:#999;transition:0.5s}
.moduleWrap dd .panelCont li .cont{float:left;width:80%}
.moduleWrap dd .panelCont li .cont a{display:block;height:100%;}
.moduleWrap dd .panelCont li .cont p{line-height:24px;transition:all .2s ease;margin-bottom:0;font-size:14px}
.moduleWrap dd .panelCont li .cont font{color:#9b9ea0}
.moduleWrap dd .panelCont li:hover a{color:#e62d31}
.moduleWrap dd .panelCont li:hover .iconfont1{transform: scale(1.2);color:#e62d31}
.moduleWrap dd .panelCont .panelClose{position:absolute;right:10px;top:2px;color:#bdbdbd;cursor:pointer}
.moduleWrap dd:hover .panelCont{visibility: visible;opacity: 1;}
.moduleWrap dd.goto-top{display:block}
.moduleWrap dd .appdown2{position:absolute;right:51px;top:0px;background:#fff;padding:5px;display:none;border: 1px solid #eee;box-shadow: -2px -1px 20px #c9c9c9;}
.moduleWrap dd .appdown2 img{width:100px}
.moduleWrap dd:hover .box{visibility: visible;opacity: 1;}
.moduleWrap dd .box{position:absolute;right:45px;bottom:-60px;visibility: hidden;opacity: 0;transition: all .3s; padding-right:10px}
.moduleWrap dd .server{border: 1px solid #eee;box-shadow:0 6px 12px 0 rgba(0,0,0,.15); background:#ffffff;padding: 10px 10px;width: 150px;color: #333;text-align: left;font-size: 14px;}
.moduleWrap dd .server em{color:#e62d31;margin-right:3px}
.moduleWrap dd .server p{margin-bottom:5px;text-align:center}
.moduleWrap dd .server p a{display: inline-block;border-radius: 3px;background: #e5e5e5;height: 28px;line-height: 28px;text-align: center;padding: 0px 10px;font-size: 12px;}
.moduleWrap dd .server p a:hover{background:#e62d31;color:#fff}
.moduleWrap dd .server p a:hover em{color:#fff}
.moduleWrap dd .codeserver{float:right; width:130px;background:#ffffff;padding: 10px 4px;border: 1px solid #eee;}
.moduleWrap dd .codeserver p{font-weight:bold}
.moduleWrap dd .codeserver img{width: 110px;height:110px;}
.moduleWrap dd .codeserverbox{width:400px }
.moduleWrap dd.icondivdd{    animation-name: tada; animation: tada 1s infinite;-webkit-animation: tada 1s infinite;}

</style>
