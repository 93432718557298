<template>
  <nav class="navbar navbar-fixed-top navbar-inverse" role="navigation">
    <div class="container-fluid">
      <div class="navbar-header" style="display:flex">
        <div>
          <a href="http://www.ccmao.net">
            <img src="../assets/image/logo.png" style="width:68px">
          </a>
        </div>
        <a class="navbar-brand" href="http://www.ccmao.net">
         <div>创创猫</div> 
          <div style="font-size:12px;opacity:0.8"> 创业要软件,就选创创猫</div>
        </a>
        <button style="margin-left:auto;border:transparent" class="navbar-toggle collapsed" type="button" data-toggle="collapse" data-target="#navbar">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      </div>
      <div id="navbar" class="navbar-collapse collapse">
        <ul class="nav navbar-nav">
          <li>
            <a href="/index.html">
              首页
              <span class="sr-only">(current)</span>
            </a>
          </li>
          <li class="dropdown">
            <a
              href="#"
              class="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              产品与服务
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu product-menu">
              <div class="row">
                <div class="col-sm-4" v-for="item in categoryList" v-bind:key="item.name">
                  <!-- <div class="title">{{item.name}}</div> -->
                  <div class="sub-title" v-for="product in item.products" v-bind:key="product.name">
                    <a @click="goProduct(product.id)">{{product.name}}</a>
                    <div class="desc">
                      <a @click="goProduct(product.id)">{{product.description}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="dropdown">
            <a
              href="#"
              class="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              文档
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu doc-menu">
              <div class="row">
                <div class="col-sm-12" v-for="item in guideList" v-bind:key="item.name">
                  <div class="title">
                    
                    <!-- <i class="iconfont iconremen"></i> -->
                   <a target="_blank" :href="item.url"><i class="iconfont" :class="item.icon" style="margin-right: 5px"></i>{{item.name}}</a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="dropdown">
            <a
              href="#"
              class="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              试用
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu try-menu">
              <div class="row">
                <div class="col-sm-12">
                  <div class="title" style="width:180px">
                    <img src="../assets/image/b2c_sp.jpg"  style="width:180px">
                    <div class="desc">关注公众号获取试用地址</div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="dropdown">
            <a
              href="#"
              class="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              价格
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu try-menu">
              <div class="row">
                <div class="col-sm-12">
                  <div class="title" style="width:180px">
                    <img src="../assets/image/ccm-store.jpg"  style="width:180px">
                    <div class="desc">扫码获取产品价格</div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a href="/version.html">版本</a>
          </li>
          <!--<li class="dropdown">
            <a
              href="#"
              class="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              品牌故事
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu brand-menu">
              <div class="row">
                <div class="col-sm-12">
                  <div class="title">
                    
                    故事的主人公L出生于80年代的一个小镇，那里经济并不发达，L的家庭并不富裕但和睦；父母虽然文化程度不高，但深知教育对子女的前途影响巨大，所以从小就对L灌输读书才是唯一出路的理念， L也很争气，在初高中时代都是名列前茅并顺利考入重点大学。这是L人生的重大转折， 大学毕业后顺利进入一家外资企业工作，并在后面的几年陆续参与国外的大型项目并在现场实施，积累了丰富的产品项目经验和沟通能力；至此L的人生都是顺利的， 他意气风发，自信心极强，认为事业一定会达到新的高度。此时互联网掀开序幕，L毅然投入到互联网创业的浪潮，他认为以自己的经验和眼界以及良好的创业环境，一定能开拓出属于自己的一片天空。 是的，他做了；是的，他失败了；是的，他走向了人生低谷；创业失败后的一个晚上，L心情低落的坐在台灯下，陪伴他的是一只养了多年的猫；他摸着那只乖巧的猫，思索着自己的过去和将来，深知此时有无数和他一样的创业者正陷入低谷；L生出一个念头，孤独的创业者都需要陪伴，就像L需要他的猫一样，这个陪伴可以是产品、可以是服务、可以是情感，那我可以成为创业者的陪伴。创创猫就这样诞生了，为创业者而生，服务创业者，但L的故事却还没有结束。

                  </div>
                </div>
              </div>
            </div>
          </li>-->
          <li>
            <a href="/about.html">关于我们</a>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li>
          <a style="font-size:16px">
              全国服务热线 400-728-8939
              <span class="sr-only">(service phone)</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { categoryList,guideList } from "../assets/js/resource.js";
export default {
  name: "header",
  data() {
    return {
      categoryList: categoryList,
      guideList: guideList
    };
  },
  props: {},
  methods: {
    goHotProduct() {
      window.location.href = "/index.html?isHot=true";
    },
    goProduct(id) {
      window.location.href = "/product.html?id=" + id;
    },
    goCase() {
      window.location.href = "/case.html";
    },
    goVersion() {
      window.location.href = "/version.html";
    },
    goBlog() {
      window.open('http://blog.ccmao.net/wordpress/index.php/blog')
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .nav {
    background-color: #000 !important;
    opacity: 0.8;
  }
}
.navbar {
  margin-bottom: 0;
  border-radius: 0;
  background: transparent;
  border: none;
}
.navbar-brand{
  color: #ffffff;
}
.navbar-brand image {
  height: 100%;
}
.navbar-nav{
  margin: 0 -15px;
}
.navbar-nav li a {
  color: #fff;
  font-size: 16px;
  padding-left: 25px;
  padding-right: 25px;
}
.product-menu {
  width: 1000px;
  text-align: left;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
}
.product-menu .title {
  color: #fff;
  font-weight: bold;
  padding-bottom: 20px;
}
.product-menu .sub-title {
  color: #fff;
  padding-bottom: 20px;
  font-size: 20px;
}
.product-menu .sub-title a {
  color: #fff;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}
.product-menu .desc {
  color: #999;
  padding: 20px 0;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1px;
}
.product-menu .desc a{
  color: #999;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}
.product-menu .sub-title a:hover {
  color: #f98020;
}

.doc-menu {
  text-align: left;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
}
.doc-menu .title{
    color: #fff;
    padding-bottom: 20px;
    width: 180px;
    text-align: left;
}
.doc-menu .title a{
    font-weight: normal;
    padding-left: 0;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
}
.doc-menu .title a:hover{
    color: #f98020;
}
.brand-menu {
  text-align: left;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
}
.brand-menu .title{
    max-width: 500px;
    color: #fff;
    padding: 20px 0;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
}
.try-menu {
  text-align: left;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  .title{
    color: #fff;
    width: 180px;
    img{
      width: 180px;
    }
    .desc{
      text-align: center;
    margin-top: 10px;
    }
  }
}
</style>
