var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-fixed-top navbar-inverse",attrs:{"role":"navigation"}},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"navbar-collapse collapse",attrs:{"id":"navbar"}},[_c('ul',{staticClass:"nav navbar-nav"},[_vm._m(1),_c('li',{staticClass:"dropdown"},[_vm._m(2),_c('div',{staticClass:"dropdown-menu product-menu"},[_c('div',{staticClass:"row"},_vm._l((_vm.categoryList),function(item){return _c('div',{key:item.name,staticClass:"col-sm-4"},_vm._l((item.products),function(product){return _c('div',{key:product.name,staticClass:"sub-title"},[_c('a',{on:{"click":function($event){return _vm.goProduct(product.id)}}},[_vm._v(_vm._s(product.name))]),_c('div',{staticClass:"desc"},[_c('a',{on:{"click":function($event){return _vm.goProduct(product.id)}}},[_vm._v(_vm._s(product.description))])])])}),0)}),0)])]),_c('li',{staticClass:"dropdown"},[_vm._m(3),_c('div',{staticClass:"dropdown-menu doc-menu"},[_c('div',{staticClass:"row"},_vm._l((_vm.guideList),function(item){return _c('div',{key:item.name,staticClass:"col-sm-12"},[_c('div',{staticClass:"title"},[_c('a',{attrs:{"target":"_blank","href":item.url}},[_c('i',{staticClass:"iconfont",class:item.icon,staticStyle:{"margin-right":"5px"}}),_vm._v(_vm._s(item.name))])])])}),0)])]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-header",staticStyle:{"display":"flex"}},[_c('div',[_c('a',{attrs:{"href":"http://www.ccmao.net"}},[_c('img',{staticStyle:{"width":"68px"},attrs:{"src":require("../assets/image/logo.png")}})])]),_c('a',{staticClass:"navbar-brand",attrs:{"href":"http://www.ccmao.net"}},[_c('div',[_vm._v("创创猫")]),_c('div',{staticStyle:{"font-size":"12px","opacity":"0.8"}},[_vm._v(" 创业要软件,就选创创猫")])]),_c('button',{staticClass:"navbar-toggle collapsed",staticStyle:{"margin-left":"auto","border":"transparent"},attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbar"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Toggle navigation")]),_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/index.html"}},[_vm._v(" 首页 "),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown","role":"button","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" 产品与服务 "),_c('span',{staticClass:"caret"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown","role":"button","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" 文档 "),_c('span',{staticClass:"caret"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown","role":"button","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" 试用 "),_c('span',{staticClass:"caret"})]),_c('div',{staticClass:"dropdown-menu try-menu"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"title",staticStyle:{"width":"180px"}},[_c('img',{staticStyle:{"width":"180px"},attrs:{"src":require("../assets/image/b2c_sp.jpg")}}),_c('div',{staticClass:"desc"},[_vm._v("关注公众号获取试用地址")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown","role":"button","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" 价格 "),_c('span',{staticClass:"caret"})]),_c('div',{staticClass:"dropdown-menu try-menu"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"title",staticStyle:{"width":"180px"}},[_c('img',{staticStyle:{"width":"180px"},attrs:{"src":require("../assets/image/ccm-store.jpg")}}),_c('div',{staticClass:"desc"},[_vm._v("扫码获取产品价格")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/version.html"}},[_vm._v("版本")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/about.html"}},[_vm._v("关于我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav navbar-nav navbar-right"},[_c('li',[_c('a',{staticStyle:{"font-size":"16px"}},[_vm._v(" 全国服务热线 400-728-8939 "),_c('span',{staticClass:"sr-only"},[_vm._v("(service phone)")])])])])
}]

export { render, staticRenderFns }