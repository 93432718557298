const categoryList = [{
        name: '电商零售',
        icon: 'icon23',
        products: [{
            id: '0001',
            name: '创创猫单商户商城(JAVA)',
            description: '为企业打造更具销售力的多渠道零售商城, 多维度满足企业电商需求, 助力企业快速进入电商时代',
            hot: true,
            icon: 'icon23',
            image: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/retail.png',
            versions: [{
                    versionNo: 'v1.7.0(Latest)',
                    date: '2024-09-03',
                    changeLogs: [{
                            value: '1. 全新的UI设计'
                        },
                        {
                            value: '2. 微信小程序沉浸式体验'
                        }
                    ]
                }, {
                    versionNo: 'v1.6.2',
                    date: '2024-07-02',
                    changeLogs: [{
                            value: '1. 用户端首页页面优化'
                        },
                        {
                            value: '2. 修复用户端提现按钮的显示问题'
                        },
                        {
                            value: '3. 直播集成视频号'
                        }
                    ]
                }, {
                    versionNo: 'v1.6.0',
                    date: '2024-06-13',
                    changeLogs: [{
                            value: '1. 升级小程序直播组件至版本1.3.0'
                        },
                        {
                            value: '2. 适配微信小程序将获取用户信息由getUserInfo迁移至getUserProfile'
                        },
                        {
                            value: '3. 修复APP支付页面的bug'
                        },
                        {
                            value: '4. 修复高并发下产生重复订单的问题'
                        },
                        {
                            value: '5. 修复商品子类目删除后仍然在前端页面显示的问题'
                        }
                    ]
                }, {
                    versionNo: 'v1.5.0',
                    date: '2023-02-24',
                    changeLogs: [{
                            value: '1. 修复支付宝支付回调时可能出现的问题'
                        },
                        {
                            value: '2. 平台端-允许在浏览器打开多个tab操作'
                        },
                        {
                            value: '3. 平台端-库存管理-商品入库'
                        },
                        {
                            value: '4. 平台端-库存管理-商品出库'
                        },
                        {
                            value: '5. 平台端-库存管理-商品盘点'
                        },
                        {
                            value: '6. 平台端-修复广告关联查询出未上架商品的issue'
                        },
                        {
                            value: '7. 平台端-修复编辑商品sku时无法显示sku图片的issue'
                        },
                        {
                            value: '8. 平台端-修复系统日志不能的显示的issue'
                        },
                        {
                            value: '9. 平台端-优化Tab切换重新刷新页面的体验'
                        },
                        {
                            value: '10. 用户端-优化秒杀和团购活动倒计时显示场景'
                        },
                        {
                            value: '11. 用户端-优化积分签到后立即显示最新的积分'
                        },
                        {
                            value: '12. 用户端-优化资金提现后余额保持2位精度'
                        },
                        {
                            value: '13. 用户端-优化用户删除订单时使用逻辑删除'
                        }
                    ]
                },
                {
                    versionNo: 'v1.4.0',
                    date: '2022-10-19',
                    changeLogs: [{
                            value: '1. 平台端-修复广告关联查询出未上架商品的issue'
                        },
                        {
                            value: '2. 平台端-修复编辑商品sku时无法显示sku图片的issue'
                        },
                        {
                            value: '3. 平台端-修复系统日志不能的显示的issue'
                        },
                        {
                            value: '4. 平台端-优化Tab切换重新刷新页面的体验'
                        },
                        {
                            value: '5. 用户端-优化秒杀和团购活动倒计时显示场景'
                        },
                        {
                            value: '6. 用户端-优化积分签到后立即显示最新的积分'
                        },
                        {
                            value: '7. 用户端-优化资金提现后余额保持2位精度'
                        },
                        {
                            value: '8. 用户端-优化用户删除订单时使用逻辑删除'
                        },
                        {
                            value: '9. 修复支付宝支付回调时可能出现的问题'
                        },
                        {
                            value: '10. 平台端-允许在浏览器打开多个tab操作'
                        }
                    ]
                },
                {
                    versionNo: 'v1.3.0',
                    date: '2021-08-09',
                    changeLogs: [{
                            value: '1. 允许按照商品搜索可用优惠券'
                        },
                        {
                            value: '2. 新增新人福利功能'
                        },
                        {
                            value: '3. 新增消费送积分功能'
                        },
                        {
                            value: '4. 支持微信公众号支付'
                        },
                        {
                            value: '5. 修复用户余额明细显示不完整的问题'
                        },
                        {
                            value: '6. 修复优惠券可重复使用的问题'
                        },
                        {
                            value: '7. 修复未绑定手机号码会员可能产生潜在bug的问题'
                        },
                        {
                            value: '8. 修复团购和秒杀订单未校验库存的问题'
                        },
                        {
                            value: '9. 系统默认使用GMT+8时区'
                        }
                    ]
                }
            ]
        }, {
            id: '0002',
            name: '创创猫连锁门店(JAVA)',
            description: '为具有线下连锁门店的企业打造更具销售力的线上线下结合的零售商城, 多端引流, 助力企业快速拓展市场',
            hot: true,
            icon: 'icongouwux',
            image: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/service.png',
            versions: [{
                versionNo: 'v1.2.0(Latest)',
                date: '2024-09-04',
                changeLogs: [{
                        value: '1. 全新的UI设计'
                    },
                    {
                        value: '2. 微信小程序沉浸式体验'
                    }
                ]
            }, {
                versionNo: 'v1.1.1',
                date: '2024-06-24',
                changeLogs: [{
                        value: '1. 用户端首页页面优化'
                    },
                    {
                        value: '2. 直播集成视频号'
                    }
                ]
            }, {
                versionNo: 'v1.1.0',
                date: '2023-02-21',
                changeLogs: [{
                        value: '1. 修改计算两点之间距离错误的issue'
                    },
                    {
                        value: '2. 修复高并发下产生重复订单的问题'
                    },
                    {
                        value: '3. 修复商品子类目删除后仍然在前端页面显示的问题'
                    },
                    {
                        value: '4. 升级小程序直播组件至版本1.3.0'
                    },
                    {
                        value: '5. 适配微信小程序将获取用户信息由getUserInfo迁移至getUserProfile'
                    },
                    {
                        value: '6. 修复广告管理查询商品的bug'
                    }
                ]
            }, {
                versionNo: 'v1.0.0',
                date: '2022-01-24',
                changeLogs: [{
                    value: '1. 稳定版本发布'
                }]
            }]
        }, {
            id: '0003',
            name: '创创猫多商户商城(JAVA)',
            description: '生态级电商平台解决方案, 支持平台自营商家入驻模式, 全渠道, 多终端覆盖, 多场景营销策略',
            hot: true,
            icon: 'iconshenghuo',
            image: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/retail.png',
            versions: [{
                    versionNo: 'v1.7.0(Latest)',
                    date: '2024-08-09',
                    changeLogs: [{
                            value: '1. 同时支持视频号直播和小程序直播,并可灵活配置'
                        },
                        {
                            value: '2. 支持隐私政策配置'
                        },
                        {
                            value: '3. 优化后台管理的内容管理功能'
                        }
                    ]
                }, {
                    versionNo: 'v1.6.1',
                    date: '2024-06-24',
                    changeLogs: [{
                            value: '1. 用户端首页页面优化'
                        },
                        {
                            value: '2. 直播集成视频号'
                        }
                    ]
                },
                {
                    versionNo: 'v1.6.0',
                    date: '2023-11-08',
                    changeLogs: [{
                            value: '1. 升级小程序直播组件至版本1.3.0'
                        },
                        {
                            value: '2. 支持多城市商户运营功能'
                        },
                        {
                            value: '3. 适配微信小程序将获取用户信息由getUserInfo迁移至getUserProfile'
                        },
                        {
                            value: '4. 修复APP支付页面的bug'
                        },
                        {
                            value: '5. 修复计算两点之间距离错误的issue'
                        },
                        {
                            value: '6. 修复高并发下产生重复订单的问题'
                        },
                        {
                            value: '7. 修复商品子类目删除后仍然在前端页面显示的问题'
                        }
                    ]
                },
                {
                    versionNo: 'v1.5.0',
                    date: '2021-05-15',
                    changeLogs: [{
                            value: '1. 修复支付宝支付回调时可能出现的问题'
                        },
                        {
                            value: '2. 平台端-应用基础设置新增是否允许城市选择选项'
                        },
                        {
                            value: '3. 用户端-首页新增城市选择功能'
                        },
                        {
                            value: '4. 平台端-允许在浏览器打开多个tab操作'
                        },
                        {
                            value: '5. 商家/平台端-库存管理-商品入库'
                        },
                        {
                            value: '6. 商家/平台端-库存管理-商品出库'
                        },
                        {
                            value: '7. 商家/平台端-库存管理-商品盘点'
                        },
                        {
                            value: '8. 平台端-修复广告关联查询出未上架商品的issue'
                        },
                        {
                            value: '9. 平台/商家端-修复编辑商品sku时无法显示sku图片的issue'
                        },
                        {
                            value: '10. 平台端-修复系统日志不能的显示的issue'
                        },
                        {
                            value: '11. 平台/商家端-优化Tab切换重新刷新页面的体验'
                        },
                        {
                            value: '12. 商家端-修复提现记录有时显示不了的issue'
                        },
                        {
                            value: '13. 用户端-优化秒杀和团购活动倒计时显示场景'
                        },
                        {
                            value: '14. 用户端-优化积分签到后立即显示最新的积分'
                        },
                        {
                            value: '15. 用户端-优化资金提现后余额保持2位精度'
                        },
                        {
                            value: '16. 用户端-优化用户删除订单时使用逻辑删除'
                        }
                    ]
                },
                {
                    versionNo: 'v1.4.0',
                    date: '2020-09-21',
                    changeLogs: [{
                            value: '1. 修复支付宝支付回调时可能出现的问题'
                        },
                        {
                            value: '2. 平台端-应用基础设置新增是否允许城市选择选项'
                        },
                        {
                            value: '3. 用户端-首页新增城市选择功能'
                        },
                        {
                            value: '4. 平台端-允许在浏览器打开多个tab操作'
                        },
                        {
                            value: '5. 商家/平台端-库存管理-商品入库'
                        },
                        {
                            value: '6. 商家/平台端-库存管理-商品出库'
                        },
                        {
                            value: '7. 商家/平台端-库存管理-商品盘点'
                        },
                        {
                            value: '8. 平台端-修复广告关联查询出未上架商品的issue'
                        },
                        {
                            value: '9. 平台端-修复系统日志不能的显示的issue'
                        },
                        {
                            value: '10. 平台/商家端-优化Tab切换重新刷新页面的体验'
                        },
                        {
                            value: '11. 用户端-优化资金提现后余额保持2位精度'
                        }
                    ]
                },
                {
                    versionNo: 'v1.0.0',
                    date: '2020-08-09',
                    changeLogs: [{
                        value: '1. 稳定版本发布'
                    }]
                }
            ]
        }]
    }, {

        name: '创创猫到家服务',
        icon: 'iconshangdian',
        products: [{
            id: '0004',
            name: '创创猫到家服务(JAVA)',
            description: '互联网+到家服务的创新型商业模式，基于互联网、物联网、AI人工智能的技术创新，结合中国家庭高品质、多样化的生活场景',
            hot: true,
            icon: 'iconshangdian',
            image: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/service.png',
            versions: [{
                    versionNo: 'v1.2.0(Latest)',
                    date: '2024-09-04',
                    changeLogs: [{
                        value: '1. 微信小程序沉浸式体验'
                    }]
                }, {
                    versionNo: 'v1.1.0',
                    date: '2024-07-16',
                    changeLogs: [{
                            value: '1. 申请成为平台服务人员时，新增身份证上传、新增查看平台服务协议'
                        },
                        {
                            value: '2. 申请成为平台服务人员时，新增所在区域验证是否已开通服务'
                        },
                        {
                            value: '3. 服务人员可按月及自定义的时间段查看资金明细及统计数据'
                        },
                        {
                            value: '4. 服务人员可随时查看各服务项目收费标准'
                        },
                        {
                            value: '5. 平台可根据订单实际情况及奖惩措施调整服务人员的收入'
                        },
                        {
                            value: '6. 平台可配置服务人员的资金提现申请时间段'
                        },
                        {
                            value: '7. 平台按城市隔离订单，以及按城市以各维度统计订单'
                        },
                        {
                            value: '8. 优化非抢单模式下的平台派单，派单时可查看服务人员的相关数据排名, 以决定派单给最优服务人员'
                        }
                    ]
                }, {
                    versionNo: 'v1.0.1',
                    date: '2024-07-02',
                    changeLogs: [{
                            value: '1. 用户端首页页面优化'
                        },
                        {
                            value: '2. 直播集成视频号'
                        }
                    ]
                },
                {
                    versionNo: 'v1.0.0',
                    date: '2024-06-16',
                    changeLogs: [{
                        value: '1. 稳定版本发布'
                    }]
                }
            ]
        }]
    },
    {
        name: '创创猫社区团购',
        icon: 'iconicon-test2',
        products: [{
            id: '0005',
            name: '创创猫社区团购(JAVA)',
            description: '真实居住社区内居民团体的一种购物消费行为，是依托真实社区的一种区域化、小众化、本地化的团购形式',
            hot: true,
            icon: 'iconicon-test2',
            image: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/retail.png',
            versions: [{
                    versionNo: 'v1.6.0(Latest)',
                    date: '2024-08-09',
                    changeLogs: [{
                            value: '1. 同时支持视频号直播和小程序直播'
                        },
                        {
                            value: '2. 支持隐私政策配置'
                        },
                        {
                            value: '3. 优化后台管理的内容管理功能'
                        },
                        {
                            value: '4. 修复团长端在核销时有时调用不起摄像头扫码的问题'
                        }
                    ]
                }, {
                    versionNo: 'v1.5.1',
                    date: '2024-07-02',
                    changeLogs: [{
                            value: '1. 用户端首页页面优化'
                        },
                        {
                            value: '2. 直播集成视频号'
                        }
                    ]
                },
                {
                    versionNo: 'v1.5.0',
                    date: '2024-01-05',
                    changeLogs: [{
                            value: '1. 修复高并发下产生重复订单的问题'
                        },
                        {
                            value: '2. 适配微信小程序将获取用户信息由getUserInfo迁移至getUserProfile'
                        },
                        {
                            value: '3. 升级小程序直播组件至版本1.3.0'
                        }
                    ]
                },
                {
                    versionNo: 'v1.4.0',
                    date: '2023-03-23',
                    changeLogs: [{
                            value: '1. 修复APP支付页面的bug'
                        },
                        {
                            value: '2. 修复商品子类目删除后仍然在前端页面显示的问题'
                        },
                        {
                            value: '3. 升级小程序直播组件至版本1.3.0'
                        }
                    ]
                },
                {
                    versionNo: 'v1.3.0',
                    date: '2022-07-21',
                    changeLogs: [{
                            value: '1. 优化用户端首页展示'
                        },
                        {
                            value: '2. 优化用户端我的页面展示'
                        },
                        {
                            value: '3. 优化各端体验'
                        },
                        {
                            value: '4. 用户使用过程中的少量bug修复'
                        }
                    ]
                },
                {
                    versionNo: 'v1.0.0',
                    date: '2021-05-10',
                    changeLogs: [{
                        value: '1. 稳定版本发布'
                    }]
                }
            ]
        }]
    },
    {
        name: '创创猫知识付费',
        icon: 'iconjiaoyu1',
        products: [{
            id: '0006',
            name: '创创猫知识付费(JAVA)',
            description: '用户引流、线上课堂、品牌推广、商业变现，全面构建知识付费闭环',
            hot: true,
            icon: 'iconjiaoyu1',
            image: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/service.png',
            versions: [{
                    versionNo: 'v1.1.0(Latest)',
                    date: '2024-08-09',
                    changeLogs: [{
                            value: '1. 同时支持视频号直播和小程序直播，优化视频号直播页面'
                        },
                        {
                            value: '2. 支持隐私政策配置'
                        },
                        {
                            value: '3. 优化后台管理的内容管理功能'
                        }
                    ]
                }, {
                    versionNo: 'v1.0.1',
                    date: '2024-07-02',
                    changeLogs: [{
                            value: '1. 用户端首页页面优化'
                        },
                        {
                            value: '2. 直播集成视频号'
                        }
                    ]
                },
                {
                    versionNo: 'v1.0.0',
                    date: '2024-03-03',
                    changeLogs: [{
                        value: '1. 稳定版本发布'
                    }]
                }
            ]
        }]
    }
]
const caseCategoryList = [{
    name: '社交电商',
    icon: 'icon23',
    cases: [{
        id: '0001',
        name: '辣木膳',
        description: '',
        hot: true,
        image: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/lamushan.png',
        qrcode: [{
            type: '小程序',
            accessUrl: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/sanshanshan.jpg',
            default: true,
        }]
    }]
}, {
    name: '电商零售',
    icon: 'icon23',
    cases: [{
        id: '0001',
        name: '购兑商城',
        description: '',
        hot: true,
        image: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/%E8%B4%AD%E5%85%91%E5%95%86%E5%9F%8E%E8%83%8C%E6%99%AF.png',
        qrcode: [{
            type: '小程序',
            accessUrl: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/%E8%B4%AD%E5%85%91%E5%95%86%E5%9F%8E%E5%B0%8F%E7%A8%8B%E5%BA%8F.jpg',
            default: true,
        }]
    }]
}, {
    name: '电商零售',
    icon: 'icon23',
    cases: [{
        id: '0001',
        name: '链仓物资',
        description: '',
        hot: true,
        image: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/lc-headerlogo.png',
        qrcode: [{
            type: '小程序',
            accessUrl: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E4%BA%8C%E7%BB%B4%E7%A0%81.jpg',
            default: true,
        }]
    }]
}, {
    name: '电商零售',
    icon: 'icon23',
    cases: [{
        id: '0001',
        name: '买朵朵',
        description: '',
        hot: true,
        image: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/613f3997-f158-40c1-94a3-46f4dbfd9253.png',
        qrcode: [{
            type: '小程序',
            accessUrl: 'https://ccmao-b2c.oss-cn-shenzhen.aliyuncs.com/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E7%A0%81.jpg',
            default: true,
        }]
    }]
}]
const hotProductSearchKey = [{
    name: '商城'
}]
const hotCaseSearchKey = [{
    name: '辣木膳'
}, {
    name: '购兑商城'
}]
const guideList = [{

        name: '创创猫单商户商城',
        icon: 'iconrili',
        url: 'http://guide.ccmao.net/b2c',
    },
    {

        name: '创创猫多商户商城',
        icon: 'iconrili',
        url: 'http://guide.ccmao.net/b2b2c',
    },
    {

        name: '创创猫连锁门店',
        icon: 'iconrili',
        url: 'http://guide.ccmao.net/ms',
    },
    {

        name: '创创猫社区团购',
        icon: 'iconrili',
        url: 'http://guide.ccmao.net/esn',
    },
    {

        name: '创创猫知识付费',
        icon: 'iconrili',
        url: 'http://guide.ccmao.net/cvc',
    },
    {

        name: '创创猫到家服务',
        icon: 'iconrili',
        url: 'http://guide.ccmao.net/house',
    }
]
const customerList = [{
        name: '云南滇沣汇农业科技有限责任公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/云南滇沣汇农业科技有限责任公司.png'
    },
    {
        name: '四川科泰数联科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/四川科泰数联科技有限公司.png'
    },
    {
        name: '重庆浪舟科技开发有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/重庆浪舟科技开发有限公司.png'
    },
    {
        name: '约码（山东）网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/约码（山东）网络科技有限公司.png'
    },
    {
        name: '北京春日鲜美商贸有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/北京春日鲜美商贸有限公司.png'
    },
    {
        name: '北京码圈信息科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/北京码圈信息科技有限公司.png'
    },
    {
        name: '沈阳凌科通信科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/沈阳凌科通信科技有限公司.png'
    },
    {
        name: '明达优嘉（北京）科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/明达优嘉（北京）科技有限公司.png'
    },
    {
        name: '易达天成（大连）科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/易达天成（大连）科技有限公司.png'
    },
    {
        name: '信创互连（天津）科技发展有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/信创互连（天津）科技发展有限公司.png'
    },
    {
        name: '芥子数据（广州）有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/芥子数据（广州）有限公司.png'
    },
    {
        name: '兰州自由飞翔信息技术服务有限责任公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/兰州自由飞翔信息技术服务有限责任公司.png'
    }, {
        name: '泰州腾翔信息科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/泰州腾翔信息科技有限公司.png'
    }, {
        name: '杭州沙用互联网有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/杭州沙用互联网有限公司.jpg'
    }, {
        name: '福州猎户网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/福州猎户网络科技有限公司.png'
    }, {
        name: '承德市天乾地坤软件科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/承德市天乾地坤软件科技有限公司.png'
    }, {
        name: '湖南知萌科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/湖南知萌科技有限公司.png'
    }, {
        name: '宏兴财税服务集团有限责任公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/宏兴财税服务集团有限责任公司.png'
    }, {
        name: '深圳市小台风信息科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/深圳市小台风信息科技有限公司.png'
    }, {
        name: '河南小囤网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/河南小囤网络科技有限公司.png'
    }, {
        name: '长沙新嗨码网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/长沙新嗨码网络科技有限公司.png'
    }, {
        name: '广东卓启云链科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广东卓启云链科技有限公司.png'
    }, {
        name: '衡水熙知科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/衡水熙知科技有限公司.png'
    }, {
        name: '福建微团网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/福建微团网络科技有限公司.png'
    }, {
        name: '河北佰业通智能科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/河北佰业通智能科技有限公司.png'
    }, {
        name: '边缘智能研究院南京有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/边缘智能研究院南京有限公司.png'
    }, {
        name: '平安国际智慧城市科技股份有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/平安国际智慧城市科技股份有限公司.png'
    }, {
        name: '成都青云优创科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/成都青云优创科技有限公司.png'
    }, {
        name: '蓝月亮(中国)有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/蓝月亮(中国)有限公司.png'
    }, {
        name: '链英(南京)区块链科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/链英(南京)区块链科技有限公司.png'
    }, {
        name: '陕西格创网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/陕西格创网络科技有限公司.png'
    }, {
        name: '汤臣倍健药业有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/汤臣倍健药业有限公司.png'
    }, {
        name: '河南小维软件科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/河南小维软件科技有限公司.png'
    }, {
        name: '厦门宇客云网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/厦门宇客云网络科技有限公司.png'
    }, {
        name: '清云科技应用（昆明）有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/清云科技应用（昆明）有限公司.png'
    }, {
        name: '四川民惠科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/四川民惠科技有限公司.png'
    }, {
        name: '山西萌虎科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/山西萌虎科技有限公司.png'
    }, {
        name: '上海伯乔智能科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/上海伯乔智能科技有限公司.png'
    }, {
        name: '武汉行愈达科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/武汉行愈达科技有限公司.png'
    }, {
        name: '安徽赢付智能科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/安徽赢付智能科技有限公司.jpg'
    }, {
        name: '武汉暴雪时代网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/武汉暴雪时代网络科技有限公司.png'
    }, {
        name: '四川亨通网智科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/四川亨通网智科技有限公司.png'
    }, {
        name: '湖南数在云尚数字科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/湖南数在云尚数字科技有限公司.png'
    }, {
        name: '哈尔滨四通计算机设备有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/哈尔滨四通计算机设备有限公司.jpg'
    }, {
        name: '西安瑞恩信息技术有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/西安瑞恩信息技术有限公司.png'
    }, {
        name: '山东极客网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/山东极客网络科技有限公司.png'
    }, {
        name: '苏州华开农创智慧科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/苏州华开农创智慧科技有限公司.jpg'
    }, {
        name: '上海赛意信息技术有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/上海赛意信息技术有限公司.png'
    }, {
        name: '广州市诚毅科技软件开发有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广州市诚毅科技软件开发有限公司.png'
    }, {
        name: '四川易家讯科科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/四川易家讯科科技有限公司.png'
    }, {
        name: '珠海夸克科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/珠海夸克科技有限公司.png'
    }, {
        name: '广东柒胜软件科技有限责任公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广东柒胜软件科技有限责任公司.png'
    }, {
        name: '地铁大数据（青岛）有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/地铁大数据（青岛）有限公司.png'
    }, {
        name: '青岛三软信息科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/青岛三软信息科技有限公司.png'
    }, {
        name: '北京四八快装网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/北京四八快装网络科技有限公司.png'
    }, {
        name: '北京卓睿优创科技有限责任公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/北京卓睿优创科技有限责任公司.png'
    }, {
        name: '快快团(厦门)电子商务有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/快快团(厦门)电子商务有限公司.png'
    }, {
        name: '北京金网拓技术有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/北京金网拓技术有限公司.png'
    }, {
        name: '重庆裴翠电子商务有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/重庆裴翠电子商务有限公司.png'
    }, {
        name: '福建亿创网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/福建亿创网络科技有限公司.png'
    }, {
        name: '中慧农牧股份有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/中慧农牧股份有限公司.png'
    }, {
        name: '厦门益游科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/厦门益游科技有限公司.png'
    }, {
        name: '北京开课吧科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/北京开课吧科技有限公司.png'
    }, {
        name: '广州叶子科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广州叶子科技有限公司.png'
    }, {
        name: '贵州爱哩信息科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/贵州爱哩信息科技有限公司.png'
    }, {
        name: '陕西鼎兴网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/陕西鼎兴网络科技有限公司.png'
    }, {
        name: '深圳市飞行轨迹航空科技有限责任公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/深圳市飞行轨迹航空科技有限责任公司.png'
    }, {
        name: '贵州零道一科技发展有限责任公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/贵州零道一科技发展有限责任公司.png'
    }, {
        name: '泉州市沃牛网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/泉州市沃牛网络科技有限公司.png'
    }, {
        name: '湖南知知网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/湖南知知网络科技有限公司.png'
    }, {
        name: '广东途正网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广东途正网络科技有限公司.png'
    }, {
        name: '合肥物罗万象信息技术有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/合肥物罗万象信息技术有限公司.png'
    }, {
        name: '江苏友邦软件有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/江苏友邦软件有限公司.png'
    }, {
        name: '豆根网络科技上海有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/豆根网络科技上海有限公司.png'
    }, {
        name: '温州城市数字科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/温州城市数字科技有限公司.png'
    }, {
        name: '四川鹊客空间网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/四川鹊客空间网络科技有限公司.png'
    }, {
        name: '荣晟共创（天津）科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/荣晟共创（天津）科技有限公司.png'
    }, {
        name: '江西金盛大药房连锁有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/江西金盛大药房连锁有限公司.png'
    }, {
        name: '福州市红鹰网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/福州市红鹰网络科技有限公司.png'
    }, {
        name: '海南中亿百纳科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/海南中亿百纳科技有限公司.png'
    }, {
        name: '杭州少侠科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/杭州少侠科技有限公司.png'
    }, {
        name: '广西华翼联创科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广西华翼联创科技有限公司.png'
    }, {
        name: '成都优屏科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/成都优屏科技有限公司.png'
    }, {
        name: '共赢链（北京）信息技术有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/共赢链（北京）信息技术有限公司.png'
    }, {
        name: '湖南红网新媒科技发展有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/湖南红网新媒科技发展有限公司.jpg'
    }, {
        name: '无锡赢鱼科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/无锡赢鱼科技有限公司.png'
    }, {
        name: '河南立宏网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/河南立宏网络科技有限公司.png'
    }, {
        name: '天津河马牛科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/天津河马牛科技有限公司.png'
    }, {
        name: '长沙全超网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/长沙全超网络科技有限公司.png'
    }, {
        name: '深圳市极客联盟网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/深圳市极客联盟网络科技有限公司.png'
    }, {
        name: '浙江行雨网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/浙江行雨网络科技有限公司.png'
    }, {
        name: '湖北黄金宝宝科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/湖北黄金宝宝科技有限公司.png'
    }, {
        name: '成都野耳科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/成都野耳科技有限公司.png'
    }, {
        name: '成都源代码教育咨询有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/成都源代码教育咨询有限公司.png'
    }, {
        name: '云南创至互达网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/云南创至互达网络科技有限公司.png'
    }, {
        name: '深圳小念想网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/深圳小念想网络科技有限公司.png'
    }, {
        name: '南京汉天软件有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/南京汉天软件有限公司.png'
    }, {
        name: '海南青羽飞鸿科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/海南青羽飞鸿科技有限公司.png'
    }, {
        name: '威海市汇成通信设备有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/威海市汇成通信设备有限公司.jpg'
    }, {
        name: '成都君吉裕隆科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/成都君吉裕隆科技有限公司.png'
    }, {
        name: '苏州鼎锐软件科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/苏州鼎锐软件科技有限公司.png'
    }, {
        name: '宁波原坊科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/宁波原坊科技有限公司.png'
    }, {
        name: '南通七和互联网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/南通七和互联网络科技有限公司.png'
    }, {
        name: '乐淘趣网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/乐淘趣网络科技有限公司.jpg'
    }, {
        name: '湖南联信科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/湖南联信科技有限公司.png'
    }, {
        name: '福州科启网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/福州科启网络科技有限公司.png'
    }, {
        name: '西安智梦源网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/西安智梦源网络科技有限公司.png'
    }, {
        name: '湖南科达创联科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/湖南科达创联科技有限公司.png'
    }, {
        name: '深圳市蚂蚁零兽科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/深圳市蚂蚁零兽科技有限公司.png'
    }, {
        name: '广州新渠汇科技应用有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广州新渠汇科技应用有限公司.png'
    }, {
        name: '大连同盟软件科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/大连同盟软件科技有限公司.png'
    }, {
        name: '横琴珈蓝贸易有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/横琴珈蓝贸易有限公司.png'
    }, {
        name: '济南金橙信息科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/济南金橙信息科技有限公司.png'
    }, {
        name: '杭州丁壹科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/杭州丁壹科技有限公司.png'
    }, {
        name: '成都深驾科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/成都深驾科技有限公司.png'
    }, {
        name: '上海奥岑企业管理中心',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/上海奥岑企业管理中心.png'
    }, {
        name: '上海新享智云科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/上海新享智云科技有限公司.png'
    }, {
        name: '潍坊富淳网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/潍坊富淳网络科技有限公司.png'
    }, {
        name: '北京市太极华青信息系统有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/北京市太极华青信息系统有限公司.png'
    }, {
        name: '四联漫画赛事运营（大连）有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/四联漫画赛事运营（大连）有限公司.png'
    }, {
        name: '四川智烨科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/四川智烨科技有限公司.png'
    }, {
        name: '青岛银河矩阵数据系统有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/青岛银河矩阵数据系统有限公司.png'
    }, {
        name: '合肥乔越商贸有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广州新渠汇科技应用有限公司.png'
    }, {
        name: '深圳市亿网信息科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/深圳市亿网信息科技有限公司.png'
    }, {
        name: '南京润吉智能科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/南京润吉智能科技有限公司.png'
    }, {
        name: '湖南华章信息科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/湖南华章信息科技有限公司.png'
    }, {
        name: '广东翔云信息科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广东翔云信息科技有限公司.png'
    }, {
        name: '河南乐万网络科技有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/河南乐万网络科技有限公司.png'
    }, {
        name: '广州仓云网络有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广州仓云网络有限公司.png'
    }, {
        name: '苏州奇凯斯信息咨询有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/苏州奇凯斯信息咨询有限公司.png'
    }, {
        name: '广州市商速信息技术有限公司',
        url: 'https://ccmao-store.oss-cn-shenzhen.aliyuncs.com/customer/广州市商速信息技术有限公司.png'
    },
]
export {
    categoryList,
    caseCategoryList,
    hotProductSearchKey,
    hotCaseSearchKey,
    guideList,
    customerList
}