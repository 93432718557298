<template>
  <div class="footer-wrapper">
    <div class="row">
      <div class="col-sm-2 footer-item">
        <div class="title">产品中心</div>
        <ul v-for="item in categoryList" v-bind:key="item.name">
          <li style="color:#fff">{{item.name}}</li>
          <li v-for="product in item.products"  v-bind:key="product.name"><a @click="goProduct(product.id)">{{product.name}}</a></li>
        </ul>
      </div>

      <div class="col-sm-2 footer-item">
        <div class="title">文档</div>
        <ul>
          <li v-for="item in guideList" v-bind:key="item.name">{{item.name}}</li>
        </ul>
      </div>
      <!-- <div class="col-sm-2 footer-item">
        <div class="title">成功案例</div>
        <ul>
          <li v-for="item in displayCaseList" v-bind:key="item.name">{{item.name}}</li>
        </ul>
      </div> -->
      <div class="col-sm-2 footer-item">
        <div class="title">商务联系</div>
        <ul>
          <li><a href="/about.html">联系我们</a></li>
        </ul>
      </div>
      <div class="col-sm-2 footer-qrcode">
        <img style="width:120px;height:120px;" src="../assets/image/b2c_sp.jpg">
        <div style="padding-top:10px">产品演示</div>
      </div>
      <div class="col-sm-2 footer-qrcode">
        <img style="width:120px;height:120px;" src="../assets/image/b2c_contact.png">
        <div style="padding-top:10px">商务联系</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12" style="text-align:center;padding:20px 20% 20px 20%;">Copyright 2019-2020 成都与他联创科技有限公司(www.ccmao.net)版权所有，并保留所有权利。
<p>地址：四川省成都市武侯区天府二街量子优加产融加速器 Tel:400-728-8939 <a href="https://beian.miit.gov.cn" target="blank">蜀ICP备19036693号-1</a></p></div>
    </div>
    
  </div>
</template>

<script>
import {categoryList,caseCategoryList,guideList} from '../assets/js/resource.js';
export default {
  name: "footer",
  data(){
    return{
      categoryList: categoryList,
      caseCategoryList: caseCategoryList,
      guideList: guideList,
      displayCaseList:[]
    }
  },
  methods:{
    goHotProduct(){
      window.location.href="/index.html?isHot=true";
    },
    goProduct(id){
      window.location.href="/product.html?id="+id;
    },
    populateALLCase() {
      var displayCaseList = [];
      this.caseCategoryList.forEach(function(category) {
        category.cases.forEach(function(product) {
          displayCaseList.push(product);
        });
      });
      this.displayCaseList = displayCaseList;
    }
  },
  props: {},
  created(){
    this.populateALLCase();
  }
};
</script>

<style scoped>
.footer-wrapper {
  background-color: #2e3033;
  color: #9699a2;
}
.footer-item {
  text-align: left;
  padding: 20px 20px 20px 80px;
}
.footer-item ul{
  list-style: none;
    padding-left: 0;
}
.footer-item ul li{
  padding:5px 0 5px 0;
}
.footer-item .title{
  font-size: 16px;
  color: #fff;
  padding-bottom: 10px;
}
.footer-item a{
  color: #9699a2;
  cursor: pointer;
  text-decoration:none;
}
.footer-item a:hover{
  color: #F98020;
}
.footer-qrcode{
  text-align: center;
  margin-top: 20px;
}
.row{
  margin: 0;
}
</style>
